// add library before code <link rel="stylesheet"
// href="https://unpkg.com/swiper/swiper-bundle.min.css" /> <script
// src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>
const $ = window.jQuery

$(document).ready(function () {
  // eslint-disable-next-line
  var slider_wrapper = new Swiper('.slider-wrapper', {
    wrapperClass: 'slider-list',
    slideClass: 'slider-item',
    navigation: {
      nextEl: '.next-slide',
      prevEl: '.prev-slide',
    },
    pagination: {
      type: 'bullets',
      el: '.pagination',
      clickable: true,
    },
    speed: 700,
    slidesPerView: 'auto',
    loop: true,
    keyboard: {
      enabled: true,
    },
  })
})
